.ImageViewer-200226 .imgview-img-wrapper {
    padding: 48px 60px;
}

.ImageViewer-200226 .imgview-img-wrapper>div {
    position: relative;
}

.ImageViewer-200226 .imgview-topright-buttons {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
}

.ImageViewer-200226 .imgview-img-tag {
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    cursor: grab;
}

.ImageViewer-200226 .imgview-loading {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.ImageViewer-200226 .imgview-loading .loading {
    position: relative;
    top: auto;
    left: auto;
}

@media screen and (max-width: 767px) {
	.ImageViewer-200226 .imgview-img-wrapper {
        padding: 20px 20px;
    }
}