.display-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 80px;
}

.display-block {
  margin-left: auto;
}

.display-times {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 22px;
}

.mob-time {
  width: 3.66%;
  text-align: center;
  font-size: 12px;
}

.mobile-block {
}

.schedule-block {
  position: relative;
  display: flex;
  width: 99%;
  padding: 0px 10px 0px 10px;
  border-top: 1px solid #d6d6d6;
  height: 32px;
}

@media screen and (max-width: 1100px) {
  .display-container {
    border: none;
    margin: 0;
  }
  .display-block {
    display: flex;
    flex-direction: row;
  }
  .display-times {
    width: 10%;
    margin-top: 30px;
    text-align: center;
  }

  .mob-time {
    font-size: 12px;
    padding: 8px 0;
  }

  .mobile-block {
    display: flex;
    width: 90%;
  }

  .schedule-block {
    border-left: 1px solid #cccccc;
    width: 15%;
  }
}
