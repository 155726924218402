.double-input .input-wrapper-1 {
  border: 1px solid grey;
  border-radius: 31px;
  min-width: 65px;
  padding-right: 5px;
  max-width: 100%;
  height: 39px;
  padding-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  width: fit-content;
}

.double-input .input-wrapper-2 {
  display: flex;
  align-items: center;
  height: 100%;
}

.double-input .input {
  border: none;
  padding: 10px 0;
  border-radius: 20px;
  width: fit-content;
  font-size: 16px;
}

.double-input .date-input {
  font-size: 11px;
  width: 140px;
}

.double-input .input:focus {
  outline: none;
}

.double-input .line {
  width: 1px;
  height: 70%;
  background: grey;
  margin: 0 4px;
}

.double-input .range-picker {
  position: absolute;
  top: 39px;
  left: 0px;
  z-index: 1000;
}

.double-input .rdrDefinedRangesWrapper {
  display: none !important;
}

.double-input .rdrDateRangePickerWrapper {
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.50), 0 5px 6px 0 rgba(0,0,0,0.30);
}