.loading {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 6px solid rgb(238, 242, 243);
  border-top-color: #29cc8f;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  position: absolute;
  top: 0%;
  left: 50%;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
