.PDFViewer-191203 {
    width: 100%;
    position: relative;
}

.PDFViewer-191203 .pdfviewer-scroll-view {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow: scroll;
}

.PDFViewer-191203 .pdfviewer-loading {
    position: absolute;
    top: 100px;
    left: 0px;
    right: 0px;
    width: 100%;
}

.PDFViewer-191203 .pdfviewer-loading .loading {
    position: relative;
    top: auto;
    left: auto;
}

.PDFViewer-191203 .ReactPDF__Document {
    background-color: rgba(0, 0, 0, 0.15);
}

.PDFViewer-191203 .ReactPDF__Document .ReactPDF__Page .ReactPDF__Page__textContent {
    overflow: hidden;
}

.PDFViewer-191203 .pdfviewer-pages-spacing {
    margin-top: 20px;
}

.PDFViewer-191203 .pdfviewer-toolbar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 44px;
    background-color: rgb(50, 54, 57);
    box-shadow: 0 7px 10px 0 rgba(47,83,151,0.20);
    color: #FFF;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -ms-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
}

.PDFViewer-191203 .pdfviewer-toolbar .pdfviewer-page-number {
    font-size: 14px;
    letter-spacing: 4px;
    padding: 0px 5px;
}

.PDFViewer-191203 :hover .pdfviewer-toolbar {
    opacity: 1;
}

.PDFViewerModal-200227 {
    position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

.PDFViewerModal-200227 .pdfviewerm-wrapper {
    padding: 48px 60px;
}

.PDFViewerModal-200227 .pdfviewerm-wrapper >div {
    position: relative;
}

.PDFViewerModal-200227 .pdfviewerm-handle {
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    cursor: grab;
    overflow: hidden;
}

.PDFViewerModal-200227 .PDFViewer-191203 {
    height: 100%;
    max-height: 100%;
}

.PDFViewerModal-200227 .PDFViewer-191203 .pdfviewer-scroll-view {
    background-color: #FFF;
    height: 100%;
    max-height: 100%;
}

.PDFViewerModal-200227 .imgview-topright-buttons {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .PDFViewerModal-200227:not(.ios) .pdfviewerm-wrapper {
        padding: 60px 20px 20px 20px;
    }
    .PDFViewerModal-200227.ios .pdfviewerm-wrapper {
        padding: 20px 20px;
    }
}

@supports (-webkit-overflow-scrolling: touch) { 
    .PDFViewer-191203 {
        padding-top: 44px;
    }

    .PDFViewer-19120 .pdfviewer-toolbar {
        opacity: 1;   
    }
}
