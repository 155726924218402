.toastContainer {
    font-size  : 14px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index    : 10;
    width: auto;
}

.notification {
    margin-bottom: 1rem;
    border-radius: 4px;
    color  : #fff;
    font-size  : 12px;
    font-weight: 500;
    text-align : center;
    padding: 16px 30px;
    transition   : .3s ease;
    background-color: rgba(0, 0, 0, 0.9);
}
