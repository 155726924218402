.workshift-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 80px;
}

.workshift-block {
  margin-left: auto;
}

.times {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 22px;
}

.time {
  width: 3.66%;
  text-align: center;
  font-size: 12px;
}

.schedule {
  position: relative;
  display: flex;
  width: 99%;
  padding: 0px 10px 0px 10px;
  border-top: 1px solid #d6d6d6;
  height: 32px;
}

.scheduleDayBlock {
  width: 11%;
}

.scheduleDay {
  font-size: 14px;
  margin: 6px 0;
}

.hoursBlock {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 21px;
}

.hourItem {
  width: 100%;
  border-radius: 1px;
  position: relative;
  padding: 6px 0;
  border-left: 1px solid #cccccc;
}

.hourItem:nth-child(2n) {
  border-left-style: dashed;
  border-left-color: #cccccc;
}

.hourItem:hover > div {
  height: 95%;
  background: rgba(216, 216, 216, 0.5) !important;
  border-radius: 4px;
  border: 0.5px dashed #9c9c9c;
}

.pickedBlock {
  height: 100%;
  width: calc(100% + 2px);
  margin: 0 -1px;
  position: relative;
}

.pickedBlock-first {
  height: 100%;
  width: calc(100% + 2px);
  margin: 0 -1px;
  position: relative;
  border-radius: 10px 0px 0px 10px;
}

.pickedBlock-last {
  height: 100%;
  width: calc(100% + 2px);
  margin: 0 -1px;
  position: relative;
  border-radius: 0px 10px 10px 0px;
}

.hourItem:hover > .pickedBlock-first {
  background: rgb(41, 204, 143) !important;
  border-radius: 10px 0px 0px 10px;
}
.hourItem:hover > .pickedBlock-last {
  background: rgb(41, 204, 143) !important;
  border-radius: 0px 10px 10px 0px;
}

.hourItem:hover > .pickedBlock {
  background: rgb(41, 204, 143) !important;
  border-radius: 0px;
}

.removeButton {
  position: absolute;
  top: 10px;
  right: -16px;
  z-index: 30;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .workshift-container {
    display: none;
  }
}
