.mobile-workshift {
  padding: 10px;
  display: flex;
  width: 100%;
}
.mobile-workshift-block {
  width: 10%;
  text-align: center;
  margin-top: 38px;
}

.times-block {
  width: 10%;
  margin-top: 35px;
  text-align: center;
}

.mobtime {
  font-size: 12px;
  padding: 8px 0;
}

.main-block {
  display: flex;
  width: 90%;
}

.schedule-day {
  border-left: 1px solid #cccccc;
  width: 15%;
}

.schedule-day > p {
  text-align: center;
}

.pickedMobileBlock-first {
  border-radius: 10px 0px 0px 10px;
}

.pickedMobileBlock-last {
  border-radius: 0px 10px 10px 0px;
}

.schedule-hour {
  position: relative;
  border-top: 1px solid #cccccc;
}

.schedule-hour:nth-child(2n) {
  border-top-style: dashed;
  border-top-color: #cccccc;
}

.mobBlock {
  padding: 8px 0;
}

.mobBlock-first {
  border-radius: 10px 10px 0px 0px;
}

.mobBlock-last {
  border-radius: 0px 0px 10px 10px;
}

.mobile-remove-btn {
  position: absolute;
  right: 0;
  top: 25px;
  z-index: 30;
  cursor: pointer;
}
