body {
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

html {
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #323232;
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
  text-transform: inherit;
  border-radius: 4px;
  min-width: 10px;
  min-height: 10px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}

.modal-container {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  color: white;
}

@page {
  size: A4;
  padding: 8mm;
  margin: 5mm;
  border: 1px solid #ccc;
  page-break-after: always;
}

@media print {
  footer {
    position: fixed;
    bottom: 10mm;
    page-break-inside: auto;
  }
}
