.fileBox {
  width: 132px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #29cc8f;
  min-width: 136px;
  height: 164px;
  color: #29cc8f;
  border-radius: 4px;
}

.image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
}

.fileName {
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
  text-align: center;
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.action {
  height: 100%;
  opacity: 0;
}

.action:hover {
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgb(190, 190, 190);
}

.inner {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  width: 80px;
  padding: 5px 0;
  color: #29cc8f;
  background: #fff;
  margin: 10px 0;
  border-radius: 4px;
}
