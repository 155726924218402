.flexrow {
    display: flex;
    flex-direction: row;
}

.flexrow .flexitem {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}

.flexrow > .flexitem-ie-fix {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1 1 100%;
}


.flexrow.hoz-center {
    justify-content: center;
}

.flexrow.hoz-right {
	justify-content: flex-end;
}
.flexrow.hoz-left {
	justify-content: flex-start;
}

.flexrow.ver-center { 
    align-items: center;
}

.flexrow.ver-top {
    align-items: flex-start;
}

.flexrow.ver-bottom {
    align-items: flex-end;
}

.flexrow.wrap {
    flex-wrap: wrap;
}

.flexrow.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flexrow.space-between {
    justify-content: space-between;
}

.flexcol {
    display: flex;
    flex-direction: column;
}

.flexcol .flexitem {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}

.flexcol > .flexitem-ie-fix {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1 1 auto;
}

.flexcol.hoz-center {
    align-items: center;
}

.flexcol.ver-center { 
    justify-content: center;
}

.flexcol.hoz-right {
    align-items: flex-end;
}

.flexcol.ver-bottom {
    justify-content: flex-end;
}

.self-stretch {
    align-self: stretch;
}

.self-center {
    align-self: center;
}


.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.mouse-over-opacity:hover {
    opacity: 0.9;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    box-shadow: 0 0 2px 0 rgba(47,83,151,0.10), 0 8px 16px 0 rgba(47,83,151,0.10);
    padding: 5px;
    font-size: 13px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

textarea {
    border: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea::-webkit-input-placeholder {
    color: rgba(31, 38, 62, 0.25) !important;
}

textarea:-moz-placeholde {
    color: rgba(31, 38, 62, 0.25) !important;
}

textarea::-moz-placeholderr {
    color: rgba(31, 38, 62, 0.25) !important;
}

textarea:-ms-input-placeholder {
    color: rgba(31, 38, 62, 0.25) !important;
}

.visible-xs,
.visible-md {
    display: none;
}

.hidden-xs.flexrow, .hidden-xs.flexcol,
.hidden-md.flexrow, .hidden-md.flexcol {
    display: flex;
}

.hidden-xs:not(.flexrow):not(.flexcol),
.hidden-md:not(.flexrow):not(.flexcol) {
    display: block;
}

:root {
    --primary-color: #29CC8F;
    --primary-color-rgb: 41, 204, 143;
    --primary-color-reverse: #FFFFFF;
}

.text-gradient:not(.vertical) {
    color: #29CC8F;
}

.text-gradient.vertical {
    color: #29CC8F;
}

.app-color-primary {
    color: #29CC8F;
}

.app-color-primary-background {
    background-color: #29CC8F;
}

.app-color-primary-border {
    border-color: #29CC8F;
}

.app-color-hover-secondary:hover {
    color: #29CC8F;
}

.app-button-primary {
    background-color: #29CC8F;
    color: #FFFFFF;
    cursor: pointer;
}

.app-button-primary.outline {
    background-color: #FFFFFF;
    color: #29CC8F;
    border-color: #29CC8F;
}

.app-button-primary.outline.mouse-over-reverse:hover {
    background-color: #29CC8F;
    color: #FFFFFF;
    border-color: #29CC8F;
}

.app-button-primary.outline.mouse-over-reverse:hover:not(.disable) {
    opacity: 1;
}

.app-button-primary.text-only {
    background-color: transparent;
    color: #29CC8F;
}

.app-button-primary.disable {
    opacity: 0.4;
    pointer-events: none;
    cursor: auto;
}

.app-button-primary:not(.disable):not(.disabled):hover {
    opacity: 0.9;
}

.app-button-primary.reverse {
    background-color: #FFFFFF;
    color: #29CC8F;
}

.app-button-primary.reverse.outline {
    background-color: #29CC8F;
    color: #FFFFFF;
    border-color: #FFFFFF;
}

.app-button-primary.disable-gray {
    pointer-events: none;
    cursor: auto;
    background-color: #E8EEF4;
    color: #97A4B1;
}

.app-button-primary.disable-gray.outline {
    border-color: #E8EEF4;
}

.app-button-archive {
    background-color: #888;
    color: #FFF;
    cursor: pointer;
}

.app-button-archive.outline {
    background-color: #FFF;
    color: #888;
    border-color: #888;
}

.app-button-archive.outline.mouse-over-reverse:hover {
    background-color: #888;
    color: #FFF;
    border-color: #888;
}

.app-button-archive.outline.mouse-over-reverse:hover:not(.disable) {
    opacity: 1;
}

.app-button-archive.text-only {
    background-color: transparent;
    color: #888;
}

.app-button-archive.disable {
    opacity: 0.4;
    pointer-events: none;
    cursor: auto;
}

.app-button-archive:not(.disable):hover {
    opacity: 0.9;
}

.app-button-archive.disable-gray {
    pointer-events: none;
    cursor: auto;
    background-color: #E8EEF4;
    color: #97A4B1;
}

.app-button-archive.disable-gray.outline {
    border-color: #E8EEF4;
}

@media screen and (max-width: 900px) {
    .visible-md {
        display: block !important;
    }

    .hidden-md {
        display: none !important;
    }

    .flexrow>.flexitem-md {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
    }
}

@media screen and (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    .hidden-xs {
        display: none !important;
    }

    .flexrow>.flexitem-xs {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
    }
}

@supports (-webkit-overflow-scrolling: touch) { 
    input, textarea {
        font-size: 16px;
    }
}